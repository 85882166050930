import React from "react";
import Home from "./components/Home";

// Ensure to import the necessary CSS in your main entry JS file or use inline styles as shown below.
const styles = {
  navbar: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent black with a bit of transparency
    color: 'white',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed', // Make navbar sticky
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  navItem: {
    textDecoration: 'none',
    color: 'white',
    margin: '0 10px',
    fontWeight: 'bold',
    position: 'relative',
  },
  activeNavItem: {
    color: '#FFD700', // Gold color for the active nav item
  },
  profileImage: {
    // Profile image styles
  },
  introText: {
    // Intro text styles
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker background for better readability
  },
  // Additional styles for the call to action button
  ctaButton: {
    padding: '10px 20px',
    backgroundColor: '#0077CC',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
};

function App() {
  return (
    <div>
      <nav style={styles.navbar}>
        <a href="#home" style={styles.navItem}>Home</a>
        <a href="#about" style={styles.navItem}>About</a>
        <a href="#portfolio" style={styles.navItem}>Portfolio</a>
        <a href="#contact" style={styles.navItem}>Contact</a>
        <a href="#contact" style={{ ...styles.navItem, ...styles.ctaButton }}>Hire Me</a>
      </nav>

      <Home />
    </div>
  );
}

export default App;
