import React, { useState, useEffect, useRef } from 'react';
import emailjs from 'emailjs-com';
import mountainImage from '../mountain.avif';
import boatsImage from '../boats.avif';
import himalImage from '../himal.avif';
import rounak from '../rounak-PhotoRoomnoback.png'



const backgroundImages = [
  mountainImage, // Nepal
  boatsImage,
  himalImage,
  // ...add more image URLs here
];

// Main container style
const mainStyle = {
  fontFamily: 'Arial, sans-serif',
  color: '#ffffff',
};

// Section style
const sectionStyle = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px',
};

// Home section style
const homeSectionStyle = {
  ...sectionStyle,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center center',
};




function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  // Refs for each section
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const portfolioRef = useRef(null);
  const contactRef = useRef(null);

  const aboutSectionStyle = {
    ...sectionStyle,
    backgroundImage: `url('${backgroundImages[currentImageIndex]}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center center',
    color: '#ffffff',
  };
  const contactSectionStyle = {
    ...sectionStyle,
    backgroundImage: `url('${backgroundImages[currentImageIndex]}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center center',
    color: '#ffffff',
};
const [formSubmitted, setFormSubmitted] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7ieh4dt', 'template_o412orf', form.current, 'aN_FxKAFfTvqGD5U2')
      .then((result) => {
          setFormSubmitted(true);
          setSubmissionMessage("Your message has been sent successfully!");
      }, (error) => {
          setFormSubmitted(false);
          setSubmissionMessage("There was an error sending your message.");
      });
  };
  const portfolioData = [
    {
      company: "UniCredit Bank",
      roles: [
        "KPI Tool Management: I was responsible for the comprehensive management of a Key Performance Indicator tool, crucial for analyzing trade data and reporting.",
        "MORO Trade System: I effectively orchestrated the MORO trade capture, approval process, and break analysis, significantly enhancing operational efficiency and risk management for securities and derivatives trading.",
        "KO Factor Tool Enhancement: Worked collaboratively to upgrade the KO Factor Tool, vital for the daily valuation of exotic options, thus improving the accuracy and efficiency of market analysis.",
        "Loan Service Tool Development: I created a VBA-based tool to facilitate streamlined financial service analysis, focusing on critical loan service metrics.",
        "Coffee Meeting Automation: Developed an automated VBA tool to streamline the organization of coffee meetings for networking purposes within the company."
      ]
    },
    {
      company: "Christian-Albrechts-Universität zu Kiel",
      roles: [
        "Statistical Risk Modeling: Executed in-depth statistical analyses for financial risk modeling.",
        "Methodological Integration: Incorporated historical simulation, Monte Carlo simulation, Variance-Covariance, GARCH, and the Hill estimator for comprehensive risk evaluation and forecasting",
        "Accuracy in Risk Prediction: developing models that proved highly accurate in predicting potential financial losses, thereby showcasing the practicality and effectiveness of the VaR methods.",
      ],
    },
    {
      company: "Paycom",
      roles: [
        "Codebase Refactor: Overhauled the architecture of web applications, utilizing PHP, MySQL, and JavaScript to improve maintainability and performance.",
        "MySQL Query Optimization: Refined and optimized SQL queries, significantly speeding up data access and reducing server load.",
        "User Interface Enhancement: Redesigned user interfaces, focusing on creating a seamless and intuitive user experience.",
        "Performance Tuning: Conducted extensive performance tuning to reduce application load times and enhance user satisfaction.",
        "Cross-Functional Collaboration: Worked alongside different teams to integrate new features and streamline application performance."
      ],
    },
    {
      company: "Hobby Lobby",
      roles: [
        "Algorithm Development: Focused on creating a MySQL-based algorithm for efficient item retrieval.",
        "Search Functionality Enhancement: Improved the accuracy and efficiency of inventory searches.",
        "Query Time Reduction: Significantly reduced the time required for inventory item queries.",
        "Inventory Process Optimization: Contributed to streamlining the overall inventory management process.",
      ],
    },
    {
      company: "American Fidelity",
      roles: [
        "Payroll Automation Implementation: Innovating an automated solution for payroll processing.",
        "Manual to Automated Transition: Transforming the payroll system from manual to automated.",
        "Significant Time Reduction: Drastically cutting down payroll processing time.",
        "Operational Efficiency Improvement: Enhancing overall business operations efficiency.",
      ],
    }
  ];

  return (
    <main style={mainStyle}>
      <header id="home" ref={homeRef} style={{ ...homeSectionStyle, backgroundImage: `url('${backgroundImages[currentImageIndex]}')` }}>
        <div style={{ marginTop:'10px',marginBottom: '20px', width: '150px', height: '150px', borderRadius: '50%', border: '3px solid white', background: `url(${rounak}) center center / cover no-repeat` }}></div>
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: '20px', borderRadius: '8px', maxWidth: '600px', fontSize: '1.1em', lineHeight: '1.6' }}>
          <h1>Rounak Bastola</h1>
          <p>Exploring the Frontiers of Finance with Code</p>
          <p>
            Blending finance with technology, I navigate the complexities of markets as a quant. My expertise in programming and quantitative analysis converge, bringing to life the stories told by numbers, trends, and market predictions. This unique blend of coding and data insights unlocks narratives, bridging statistical theories with real-world financial dynamics. Join me at the dynamic intersection of programming and financial acumen.
          </p>
        </div>
      </header>

      <section id="about" ref={aboutRef} style={aboutSectionStyle}>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: '20px', borderRadius: '8px', maxWidth: '600px', fontSize: '1.1em', lineHeight: '1.6' }}>
      <h2>About Me</h2>
        <p>
          I'm Rounak Bastola, a quantitative analyst with a Bachelor's in Applied Mathematics, concentrated in Finance, and a Master's in Quantitative Finance. My professional journey includes enhancing database efficiency with MySQL at Paycom, and at UniCredit, I applied SQL and VBA to analyze risks within millions of trades. My practical skills were further sharpened by developing payroll solutions at American Fidelity and streamlining inventory management at Hobby Lobby. Driven by a passion for financial markets and data analysis, I excel at transforming complex data into actionable insights.
        </p>
        </div>
      </section>

      <section id="portfolio" ref={portfolioRef} style={aboutSectionStyle}>
      <h2>Portfolio</h2>
      {portfolioData.map((item, index) => (
        <div key={index} style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: '20px', borderRadius: '8px', maxWidth: '600px', fontSize: '1.1em', lineHeight: '1.6', marginBottom: '20px' }}>
          <h3>{item.company}</h3>
          {item.roles ? (
            <ul>
              {item.roles.map((role, roleIndex) => (
                <li key={roleIndex}>{role}</li>
              ))}
            </ul>
          ) : (
            <p>{item.description}</p>
          )}
        </div>
      ))}
    </section>

    <section id="contact" ref={contactRef} style={contactSectionStyle}>
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: '20px', borderRadius: '8px', maxWidth: '600px', fontSize: '1.1em', lineHeight: '1.6', textAlign: 'center' }}>
          <h2>Contact Me</h2>
          {!formSubmitted ? (
            <>
              <p>If you're interested in working together, let's get in touch!</p>
              <form ref={form} onSubmit={sendEmail} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <input type="text" name="from_name" placeholder="Your Name" style={{ padding: '10px', margin: '5px', width: '80%' }} />
                <input type="email" name="from_email" placeholder="Your Email" style={{ padding: '10px', margin: '5px', width: '80%' }} />
                <textarea name="message" placeholder="Your Message" style={{ padding: '10px', margin: '5px', width: '80%', height: '150px' }} />
                <button type="submit" style={{ padding: '10px 20px', margin: '5px', backgroundColor: '#0077CC', color: 'white', border: 'none', borderRadius: '5px' }}>Send Message</button>
              </form>
            </>
          ) : (
            <>
              <p style={{ color: 'red', fontWeight: 'bold' }}>{submissionMessage}</p>
              <p>Thank you for getting in touch!</p>
            </>
          )}
        </div>
      </section>
    </main>
  );
}

export default Home;
